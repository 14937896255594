import React from 'react'
import IndexPage from '../components'
import Tours from '../components/tours'
import Faq from '../components/faq'
import Button from '../components/Button'
import getParameterByName from '../utils/getParameterByName'

export default data => ({ data: { image } }) => {
  const term = getParameterByName('term')
  const { collections, slug, cityNames } = data
  const heading =
    (!term ? `Экскурсии` : `${term} экскурсии`) +
    ` ` +
    (cityNames ? `с мобильным гидом в ${cityNames[1]}` : 'с мобильным гидом')
  return (
    <IndexPage
      gatsbyCover={image.childImageSharp}
      action={
        <div className="Marged">
          <Button theme="pretty" href="#how" data-scroll>
            Как это работает
          </Button>
        </div>
      }
      heading={heading}
      {...data}
    >
      <div className="wrapped">
        <Tours collections={collections} slug={slug} id="tours" />
        <Faq />
      </div>
    </IndexPage>
  )
}
