import React from 'react'

export default ({ collections, slug, ...props }) => (
  <div {...props}>
    {collections.map((collectionSlug, i) => (
      <div
        key={i}
        dangerouslySetInnerHTML={{
          __html: `
          <iframe 
            title="widget" 
            class="js-surpriseme-widget" 
            src="https://surprizeme.ru/api/products/widget/?ownTours=false&topFallback=true&lang=ru&count=5&logo=false&caption=true&coupon=51&widgetBar=false&city=${slug}&collection=${collectionSlug}&simpleTitle=true" 
            width="100%" 
            height="300px" 
            frameborder="0" 
            scrolling="no"></iframe>
        `,
        }}
      />
    ))}
  </div>
)
