import React from 'react';
import Section from './section';
import Details from './details';

export default () => (
  <Section>
    <div className="q-a">
      <h2>Ответы на вопросы</h2>
      <Details className="NanoMarged" title={"Правда, что экскурсии Surprise Me дешевле обычных?"} defaultActive={true}>
        Средняя стоимость групповой экскурсии заграницей 20 € за одного человека, то есть за компанию 2-5 человек вы заплатите 40-100 €. У нас вы платите 15-30 € за экскурсию и вы можете проходить их компанией до 5 человек не доплачивая. 
      </Details>
      <Details className="NanoMarged" title={"Как узнать дату и время экскурсии?"}>
        У экскурсии нет конкретной даты и времени. Так как экскурсию проводит мобильное приложение Surprise Me — вы начинаете экскурсию в удобное вам время.
      </Details>
      <Details className="NanoMarged" title={"Это похоже на квесты? Я хочу просто погулять и не париться, как мне выбрать экскурсию?"}>
        Вам не придется решать сложные ребусы. Задания в экскурсиях только обращают ваше внимание на интересные детали городских локаций.
      </Details>
    </div>
  </Section>
);