import generic from './generic';
import maksim from '../images/reviews/maksim.png';
import cover from '../images/covers/tbilisi.jpg';

export default {
    ...generic,
    slug: 'tbilisi',
    cover,
    collections: [
      "popular",
    ],
    cityNames: ['Тбилиси ', 'Тбилиси'],
    reviews: [
      {
        avatar: "https://app.surprizeme.ru/media/users/15020/marinaavatar.png",
        name: "Марина",
        text: "🔥Очень понравился подбор мест! Самостоятельно даже и не подумаешь завернуть туда ил не обратиить внимание. В старой парадной очень круто, старый магазинчик милый, особенно, когда понимаешь, насколько он старый и другие объекты здоровские! ",
        product: {
          name: "Старый город Тбилиси: в поисках древнего Тифлиса",
          link: "https://surprizeme.ru/ru/saint-petersburg/unusual/neformalnye-dostoprimechatelnosti-peterburga/"
        }
      },
      {
        avatar: maksim,
        name: "Максим",
        text: "Проникся атмсоферой Тбилиси! Маршрут привел в парадную к красивым витражам 😻",
        product: {
          name: "«Джигит с Уолл-стрит». Прогулка по району Сололаки",  
          link: "https://surprizeme.ru/ru/tbilisi/popular/tbilisi-iii-dzhigit-s-uoll-strit/"
        }
      }
    ]   
};